import React from "react";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa"; // Import the eye icon from react-icons
import moment from "moment"; // To format the date



// Function to handle the "View" button click


function Reports() {
  // Sample data with added 'date' field
  const data = [

    // Add more rows as needed
  ];

  // Column definition with the new Date column
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Course",
      selector: (row) => row.course,
      sortable: true,
    },
    {
      name: "Approved Leads",
      selector: (row) => row.approvedLeads,
      sortable: true,
    },
    {
      name: "Rejected Leads",
      selector: (row) => row.rejectedLeads,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.paymentStatus,
      sortable: true,
    },
   
    {
      name: "Date",
      selector: (row) => moment(row.date).format("MMMM Do YYYY"), // Format the date
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <button onClick={() => handleViewClick(row.id)} style={{ background: "none", border: "none", cursor: "pointer" }}>
          <FaEye /> {/* Add the eye icon */}
        </button>
      ),
      // Render the View button or icon (eye icon)
    },
  ];
  const handleViewClick = (id) => {
    console.log(`View details for row with ID: ${id}`);
  };
  return (
    <div className="reports">
      <DataTable
        title="Reports"
        columns={columns}
        data={data}
        pagination
        responsive
      />
    </div>
  );
}

export default Reports;
