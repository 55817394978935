import React from 'react';
import DataTable from 'react-data-table-component';
import './Payment.css';
import { FaEye, FaDownload } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip'; // Corrected import

function Payment() {
  // Sample Data for the Table (Payment records)
  const paymentData = [
    { id: 1, paymentId: 'P12345', course: 'Course A', amount: 500, paymentStatus: 'Paid', paymentDate: '2024-12-01' },
    { id: 2, paymentId: 'P12346', course: 'Course B', amount: 300, paymentStatus: 'Paid', paymentDate: '2024-12-02' },
    { id: 3, paymentId: 'P12347', course: 'Course C', amount: 150, paymentStatus: 'Pending', paymentDate: '2024-12-03' },
    { id: 4, paymentId: 'P12348', course: 'Course D', amount: 1000, paymentStatus: 'Paid', paymentDate: '2024-12-01' },
    { id: 5, paymentId: 'P12349', course: 'Course E', amount: 200, paymentStatus: 'Pending', paymentDate: '2024-12-04' },
  ];

  // Columns Definition
  const columns = [
    { name: 'Payment ID', selector: (row) => row.paymentId, sortable: true },
    { name: 'Course', selector: (row) => row.course, sortable: true },
    { name: 'Amount', selector: (row) => row.amount, sortable: true },
    { name: 'Payment Status', selector: (row) => row.paymentStatus, sortable: true },
    { name: 'Payment Date', selector: (row) => row.paymentDate, sortable: true },
    {
      name: 'Action',
      cell: (row) => (
        <>
          {/* View Icon with Tooltip */}
          <span
            data-tip
            data-for={`view-${row.paymentId}`} // Tooltip for View
            className="icon-view"
            onClick={() => viewPaymentDetails(row.paymentId)}
          >
            <FaEye />
          </span>
          <Tooltip id={`view-${row.paymentId}`} place="top" effect="solid">
            View Payment
          </Tooltip>

          {/* Download Icon with Tooltip (only for paid payments) */}
          {row.paymentStatus === 'Paid' && (
            <>
              <span
                data-tip
                data-for={`download-${row.paymentId}`} // Tooltip for Download
                className="icon-download ml-2"
                onClick={() => downloadReceipt(row.paymentId)}
              >
                <FaDownload />
              </span>
              <Tooltip id={`download-${row.paymentId}`} place="top" effect="solid">
                Download Receipt
              </Tooltip>
            </>
          )}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px', // You can adjust the width of the "Action" column if necessary
    },
  ];

  // Function to Handle Payment Details View
  const viewPaymentDetails = (paymentId) => {
    alert(`View details for payment ID: ${paymentId}`);
    // Implement your view logic, like opening a modal or redirecting to a detailed page.
  };

  // Function to Handle Download Receipt (Assume it's a text file or PDF)
  const downloadReceipt = (paymentId) => {
    const payment = paymentData.find((payment) => payment.paymentId === paymentId);
    const receiptContent = `Payment Receipt:
    Payment ID: ${payment.paymentId}
    Course: ${payment.course}
    Amount: $${payment.amount}
    Payment Status: ${payment.paymentStatus}
    Payment Date: ${payment.paymentDate}`;

    // Create a blob with the receipt content (as plain text or PDF)
    const blob = new Blob([receiptContent], { type: 'text/plain' });

    // Create a link element and simulate a click to download the receipt
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `receipt_${paymentId}.txt`; // Change to .pdf if generating PDF
    link.click();
  };

  // Calculate the total payment amount
  const totalPayment = paymentData.reduce((acc, payment) => acc + payment.amount, 0);

  return (
    <div className="container-fluid main_wrapper h-100 p-lg-3 p-md-2 p-sm-2">
      <h2 className="mb-4">Payment Overview</h2>

      <DataTable
        columns={columns}
        data={paymentData}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="400px"
        subHeader
        subHeaderComponent={
          <div className="total-payment">
            <strong>Total Payments: </strong>
            <span>${totalPayment}</span>
          </div>
        }
      />
    </div>
  );
}

export default Payment;
