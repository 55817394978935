import React, { useState, useEffect } from 'react';
import './Logout.css';

function Logout() {
    const [countdown, setCountdown] = useState(10);  // Countdown timer for auto-logout

    // Function to handle Confirm Logout
    const handleLogout = () => {
        // Here, you can clear session storage or perform the actual logout action
        // For example: localStorage.removeItem('authToken');
        // Then redirect to login page
        sessionStorage.setItem('submitted', 'false');
        window.location.href = '/'; 
    };

    // Function to handle Cancel Logout
    const handleCancel = () => {
        // Redirect to home or the previous page
        window.history.back();
    }; 
    // Countdown effect
    useEffect(() => {
        if (countdown === 0) {
            handleLogout();
        } else {
            const timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [countdown]);

    return (
        <div className="logout-wrapper">
            <div className="logout-content">
                <h2>Log Out Confirmation</h2>
                <p className="logout-description">
                    You are about to log out of your account. Any unsaved work will be lost.
                </p>

                <div className="logout-timer">
                    <p>Your session will expire in <strong>{countdown}</strong> seconds.</p>
                </div>

                <div className="logout-actions">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <button className="btn btn-primary w-100" onClick={handleLogout}>
                                Confirm Logout
                            </button>
                        </div>
                        <div className='col-lg-6'>
                            <button className="btn btn-secondary w-100" onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>


                </div>

                <div className="logout-info">
                    <p>If you do not confirm logout, you'll be redirected back to your previous page.</p>
                </div>
            </div>
        </div>
    );
}

export default Logout;
