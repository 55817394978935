import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Login.css';

function Login({ submitted, setSubmitted }) {

    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const validate = () => {
        let isValid = true;
        const newErrors = {};

        // Email validation
        if (!formData.email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
            isValid = false;
        }

        // Password validation
        if (!formData.password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (formData.password.length < 2) {
            newErrors.password = 'Password must be at least 6 characters';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the form data first
        if (validate()) {


            // Data to send to the API
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "email": formData?.email,
                "password": formData?.password
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            // Perform the API call to login
            fetch("https://api.theleadsense.com/api/v1/login", requestOptions)
                .then((response) => response.json())  // Parse the JSON response
                .then((result) => {
                    console.log(result);
                    setSubmitted(true);
                    // Handle the response from the API
                    if (result.status == 200) {
                        setSubmitted(true);
                     
                        // console.log('Login successful', result);
                        sessionStorage.setItem('Token', result?.data?.authToken)
                    
                    } else {
                        // Handle failure (show error message, etc.)
                        console.error('Login failed', result.message);
                    }
                })
                .catch((error) => {
                    console.error('Error occurred while making the API request', error);
                });

        } else {
            setSubmitted(false);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <div className='login'>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="card p-4" style={{ width: '400px' }}>
                    <h3 className="text-center">Login</h3>
                    {submitted && <div className="alert alert-success">Login successful!</div>}
                    <form>
                        <div className="mb-3">
                            <label htmlFor="email">
                                Email
                            </label>
                            <input
                                type="email"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <div className="invalid-feedback" style={{ display: "block", textAlign: "start" }}>{errors.email}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password">
                                Password
                            </label>
                            <input
                                type="password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            {errors.password && <div className="invalid-feedback" style={{ display: "block", textAlign: "start" }}>{errors.password}</div>}
                        </div>
                        <button type="submit" className="btn btn-primary w-100" onClick={handleSubmit}>
                            Login
                        </button>
                    </form>
                    <div className="mt-3 text-center">
                        <p>
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </p>
                        <p>
                            Don't have an account? <Link to="/signup">Sign Up</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
