import React from "react";
import { useQuery } from "react-query";
import './Dashboard.css';
import moment from "moment";

// Fetch function to get the lead details
const fetchLeadDetails = async () => {
    const token = sessionStorage.getItem("Token");
    if (!token) {
        throw new Error("No authentication token found!");
    }

    const myHeaders = new Headers();
    myHeaders.append("authtoken", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const response = await fetch("https://api.theleadsense.com/api/v1/count/details", requestOptions);
    const result = await response.json();

    if (result?.data?.counts) {
        return result.data.counts;
    } else {
        throw new Error("No lead data found.");
    }
};

function Dashboard() {
    const user_name = sessionStorage.getItem('user_name');
    const currentDate = moment().format("MMMM D, YYYY");

    // Using useQuery to fetch lead details
    const { data: leadsDetails, error, isLoading } = useQuery("leadDetails", fetchLeadDetails);

    // Destructure the data
    const { totalLeads, openLead, rnr, progress, prospect, goodLead } = leadsDetails || {};

    return (
        <>
            <div className="dashboard">
                <div className="card afff" style={{ width: "100%", maxWidth: "none" }}>
                    <div className="" style={{ marginLeft: "43px", marginTop: "20px" }}>
                        <div className="text-start">
                            <small style={{ color: "white" }}>{currentDate}</small>
                            <div
                                style={{
                                    bottom: "0",
                                    position: "absolute",
                                    marginBottom: "50px",
                                    color: "white",
                                }}
                            >
                                <h4>Welcome Back {user_name}</h4>
                                <small>Always stay updated with the Leadsense portal</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="enq">
                    <div className="d-flex">
                        {/* Approved Leads Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", marginRight: "15px", padding: "20px" }}
                        >
                            <h3>Number of Leads</h3>
                            {isLoading ? (
                                <h2>Loading...</h2>
                            ) : error ? (
                                <h2 className="text-danger">Error: {error.message}</h2>
                            ) : (
                                <h2 className="h2tag">{totalLeads || "0"}</h2>
                            )}
                        </div>

                        {/* Prospect Leads Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", marginRight: "15px", padding: "20px" }}
                        >
                            <h3>Rejected  Leads</h3>
                            {isLoading ? (
                                <h2>Loading...</h2>
                            ) : error ? (
                                <h2 className="text-danger">Error: {error.message}</h2>
                            ) : (
                                <h2 className="h2tag">{prospect || "0"}</h2>
                            )}
                        </div>

                        {/* Good Leads Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", marginRight: "15px", padding: "20px" }}
                        >
                            <h3>Good Leads</h3>
                            {isLoading ? (
                                <h2>Loading...</h2>
                            ) : error ? (
                                <h2 className="text-danger">Error: {error.message}</h2>
                            ) : (
                                <h2 className="h2taginr">{goodLead || "0"}</h2>
                            )}
                        </div>

                        {/* Total Lead Card */}
                        <div
                            className="card"
                            style={{ width: "24%", height: "200px", padding: "20px" }}
                        >
                            <h3>Total Leads</h3>
                            {isLoading ? (
                                <h2>Loading...</h2>
                            ) : error ? (
                                <h2 className="text-danger">Error: {error.message}</h2>
                            ) : (
                                <h2 className="h2taginr">{totalLeads || "0"}</h2>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
