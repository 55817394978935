import React from 'react';
import Assets from '../../Assets/Assets';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Appcontent from './Appcontent';


function DefaultLayout() {
    const menuItems = [
        { name: 'Dashboard', link: '/', icon: '📊' },  // Changed from 🏠 to 📊 (Bar Chart)
        { name: 'Lead Master', link: '/leads', icon: '📘' },  // Changed from 👤 to 📘 (Book)
        { name: 'Reports', link: '/reports', icon: '📑' },  // Added Reports with 📑 (Page with a bar chart)
        { name: 'Logout', link: '/logout', icon: '🔓' },  
    ];



    return (
        <div className="dashboard-layout">
            <Sidebar menuItems={menuItems} logo={Assets?.Logo} />
            <div className="main-content">
                <Header
                    logo={Assets?.Logo}
                    userName="John Doe"
                    profilePhoto={Assets?.Login_b}
                />
                <div className="contents" style={{ textAlign: "start", paddingLeft: "19%", paddingTop: "6%" }}>
                    <Appcontent />
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;
