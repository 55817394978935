import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Login from './pages/Login/Login';
import DefaultLayout from './component/Default/Default';
import './App.css';

// Create a QueryClient instance
const queryClient = new QueryClient();

function App() {
  const [submitted, setSubmitted] = useState(() => {
    const savedState = sessionStorage.getItem("submitted");
    return savedState === "true";
  });

  useEffect(() => {
    sessionStorage.setItem("submitted", submitted);
  }, [submitted]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <Routes>
            {submitted ? (
              <Route path="/*" element={<DefaultLayout />} />
            ) : (
              <Route
                path="/"
                element={<Login submitted={submitted} setSubmitted={setSubmitted} />}
              />
            )}
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
