import React, { useState } from 'react';
import './Settings.css';

function Settings() {
  // State for profile and password settings
  const [username, setUsername] = useState('john_doe');
  const [email, setEmail] = useState('johndoe@example.com');
  const [phone, setPhone] = useState('123-456-7890');
  const [description, setDescription] = useState('Web Developer');
  const [receiveEmails, setReceiveEmails] = useState(true);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [previousPassword, setPreviousPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  // Handle profile update
  const handleProfileUpdate = (e) => {
    e.preventDefault();
    alert('Profile updated successfully!');
  };

  // Handle password change
  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      if (previousPassword === 'currentPassword') {
        alert('Password updated successfully!');
      } else {
        setErrorMessage('Incorrect previous password!');
      }
    } else {
      setErrorMessage('Passwords do not match!');
    }
  };

  return (
    <div className="settings-container">
      <h2 className="settings-title">Settings</h2>

      {/* Profile Settings Section */}
      <div className="settings-section">
        <h3>Profile Settings</h3>
        <form onSubmit={handleProfileUpdate}>
          <div className="input-group">
            <label>Full Name:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your full name"
            />
          </div>
          <div className="input-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>
          <div className="input-group">
            <label>Phone Number:</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone number"
            />
          </div>
          <div className="input-group">
            <label>Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a short description about yourself"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Update Profile
          </button>
        </form>
      </div>

      {/* Notification Preferences Section */}
      <div className="settings-section">
        <h3>Notification Preferences</h3>
        <form>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={receiveEmails}
                onChange={() => setReceiveEmails(!receiveEmails)}
              />
              Receive email notifications
            </label>
          </div>
          <button type="button" className="btn btn-secondary">
            Save Preferences
          </button>
        </form>
      </div>

      {/* Change Password Section */}
      <div className="settings-section">
        <h3>Change Password</h3>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handlePasswordChange}>
          <div className="input-group">
            <label>Previous Password:</label>
            <input
              type="password"
              value={previousPassword}
              onChange={(e) => setPreviousPassword(e.target.value)}
              placeholder="Enter your current password"
              required
            />
          </div>
          <div className="input-group">
            <label>New Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your new password"
              required
            />
          </div>
          <div className="input-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default Settings;
