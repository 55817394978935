import React from 'react';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Courses from "../../pages/Courses/CoursesView";
import Universitys from '../../pages/Universitys/Universitys';
import Reports from '../../pages/Reports/Resports';
import Payment from '../../pages/Payment/Payment'
import Settings from '../../pages/Settings/Settings';
import Logout from '../../pages/Logout/Logout';
import Leads from '../../pages/Leads/Leads';

const routes = [
  { path: "/", name: "dashboard", element: Dashboard, exact: true },
  { path: "/courses", name: "courses", element: Courses, exact: true },
  { path: "/university", name: "University", element: Universitys, exact: true },
  { path: "/reports", name: "reports", element: Reports, exact: true },
  { path: "/payments", name: "payments", element: Payment, exact: true },
  { path: "/settings", name: "payments", element: Settings, exact: true },
  { path: "/logout", name: "payments", element: Logout, exact: true },
  { path: "/leads", name: "payments", element: Leads, exact: true },
];

export default routes;
