import Login_b from './Login_b.webp'
import LS_logo from './LS-logo.png'
import Affiliate from './affiliate.png'
import Liba from './LIBA.png'
import Manipal from './Maninipal.png'
const Assets = {
    Login_b: Login_b,
    Logo: LS_logo,
    Affiliate: Affiliate,
    LibaLogo: Liba,
    Manipallogo: Manipal
}
export default Assets