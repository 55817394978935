import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-modal"; // Import React Modal
import ClipboardJS from "clipboard"; // Import ClipboardJS
import './CoursesView.css';

// Modal setup (for accessibility)
Modal.setAppElement("#root");

function Courses() {
    // State to control modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [utmTag, setUtmTag] = useState("");
    const [courseName, setCourseName] = useState("");

    // Sample Data for the Table
    const data = [
        { id: 1, course: "Course A", approvedLeads: 50, rejectedLeads: 10, totalLeads: 60, paymentStatus: "Paid", amount: 500 },
        { id: 2, course: "Course B", approvedLeads: 30, rejectedLeads: 5, totalLeads: 35, paymentStatus: "Pending", amount: 200 },
        { id: 3, course: "Course C", approvedLeads: 20, rejectedLeads: 15, totalLeads: 35, paymentStatus: "Paid", amount: 150 },
        { id: 4, course: "Course D", approvedLeads: 80, rejectedLeads: 10, totalLeads: 90, paymentStatus: "Paid", amount: 1000 },
        { id: 5, course: "Course E", approvedLeads: 40, rejectedLeads: 5, totalLeads: 45, paymentStatus: "Pending", amount: 300 }
    ];

    // Table Columns
    const columns = [
        { name: "S.No", selector: (row, index) => index + 1, sortable: true, width: "70px" },
        { name: "Courses", selector: (row) => row.course, sortable: true },
        { name: "Approved Leads", selector: (row) => row.approvedLeads, sortable: true },
        { name: "Rejected Leads", selector: (row) => row.rejectedLeads, sortable: true },
        { name: "Total Leads", selector: (row) => row.totalLeads, sortable: true },
        { name: "Payment Status", selector: (row) => row.paymentStatus, sortable: true },
        { name: "Amount", selector: (row) => row.amount, sortable: true },
        {
            name: "Action",
            cell: (row) => (
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => generateUTMTag(row.course)}
                >
                    Generate UTM
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ];

    // Function to Handle UTM Tag Generation
    const generateUTMTag = (courseName) => {
        const baseUrl = "https://iop.liba.edu";
        const utm = `?utm_source=website&utm_medium=courses&utm_campaign=${courseName}`;
        const fullUrl = `${baseUrl}${utm}`;

        setUtmTag(fullUrl);
        setCourseName(courseName);
        setIsModalOpen(true);

        // Initialize ClipboardJS for the copy button
        new ClipboardJS('.copy-btn');
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container-fluid main_wrapper h-100 p-lg-3 p-md-2 p-sm-2">
            <h2 className="mb-4">Courses Overview</h2>

            <DataTable
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                fixedHeader
                fixedHeaderScrollHeight="400px"
            />

            {/* Modal for UTM Tag */}
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-content" overlayClassName="modal-overlay">
                <h2>Generated UTM Tag for {courseName}</h2>
                <div className="utm-content">
                    <p>
                        This is the link to promote the course. Use this URL to track leads coming from your marketing efforts.
                    </p>
                    <input
                        type="text"
                        value={utmTag}
                        readOnly
                        className="utm-input"
                    />
                    <button
                        className="copy-btn btn btn-secondary btn-sm"
                        data-clipboard-text={utmTag} // Clipboard data attribute
                    >
                        Copy UTM Tag
                    </button>
                    <p className="utm-description">
                        Once the lead is generated, it will show up on your dashboard for further tracking.
                    </p>
                    <button onClick={closeModal} className="btn btn-danger btn-sm">
                        Close
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default Courses;
