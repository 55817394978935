import React from "react";
import './Universitys.css';  // Optional: For custom styling
import Assets from "../../Assets/Assets";
import { useNavigate } from "react-router-dom";

function Universitys() {
    const universities = [
        {
            name: "LIBA",
            logo: Assets?.LibaLogo,
            background: Assets?.Login_b, // Replace with actual logo URL
            perLeadOffer: 50,
            additionalInfo: "Some other details about LIBA."
        },
        {
            name: "Manipal",
            logo: Assets?.Manipallogo, // Replace with actual logo URL
            background: Assets?.Login_b,
            perLeadOffer: 60,
            additionalInfo: "Some other details about Manipal."
        },
        {
            name: "University X",
            logo: "https://path-to-university-x-logo.png", // Replace with actual logo URL
            perLeadOffer: 45,
            additionalInfo: "Some other details about University X."
        }
    ];
    const Navigate = useNavigate()

    const handleNavigate = () => {
        Navigate('/courses')
    }

    return (
        <div className="container-fluid main_wrapper h-100 p-lg-3 p-md-2 p-sm-2">
            <h2 className="mb-4">Universities List</h2>

            <div className="row">
                {universities.map((university, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
                        <div className="card" style={{ height: "400px", padding: "10px" }}>
                            <div style={{ position: "relative" }}>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        position: "absolute",
                                        width: "100px",
                                        borderRadius: "10px",
                                        padding: "5px",
                                        top: "0",
                                        left: "0"
                                    }}

                                >
                                    <img src={university.logo} style={{ width: "100%" }} className="card-img-top" alt={university.name} />
                                </div>
                                <img
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        height: "200px"
                                    }}
                                    src={Assets?.Login_b}></img>

                                <div className="card-body">
                                    <h5 className="card-title">{university.name}</h5>
                                    <p className="card-text">
                                        <strong>Per Lead Offer:</strong> ₹{university.perLeadOffer}<br />
                                        <strong>Details:</strong> {university.additionalInfo}
                                    </p>
                                    <button className="btn btn-primary w-100" onClick={handleNavigate}>Start Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Universitys;
